import { timezoneMapping } from "../components/Timezone/Timezone.js"
import moment from "moment-timezone";
import ICAL from 'ical.js';
import config from '../web3/web3Config.json';

export const handleAttendees = (attendees) => {
    if (Array.isArray(attendees)) {
        return attendees.map(attendee => ({
            key: "guest",
            value: attendee?.value?.toString().split(":")[(attendee?.value?.toString().split(":").length - 1)] || null,
        }));
    } else if (attendees) {
        return [{

            key: "guest",
            value: attendees?.value?.toString().split(":")[(attendees?.value?.toString().split(":").length - 1)] || null,
        }];
    }
    return [];
}

export const convertingTimeFormat = (year, month, date, hour, minutes) => {
    const seconds = "00";
    const localDate = new Date(year, month, date, hour, minutes, seconds);
    const offsetInMilliseconds = new Date().getTimezoneOffset() * 60000;
    const utcDate = new Date(localDate.getTime() - offsetInMilliseconds);
    const inputDate = new Date(utcDate);
    const format = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    const formatted_time = inputDate.toLocaleString("en-US", format);
    return formatted_time;
}

export const convertToUtc = (time, timezone) => {
    const convertedTime = time?.split("Z")[0];
    const timeZoneToUse = timezoneMapping[timezone] || timezone || 'Etc/UTC';

    return moment.tz(convertedTime, timeZoneToUse).utc().format();
}

export const formatDateTime = (dateTime) => ({
    year: dateTime.substring(0, 4),
    month: dateTime.substring(5, 7),
    date: dateTime.substring(8, 10),
    hour: dateTime.substring(11, 13),
    minute: dateTime.substring(14, 16),
});

export const getEventPrams = async (event, statusKey, start, end) => {
    const eventDetails = event?.event || {};
    const params = {
        uid: event.event.UID,
        title: event.event.SUMMARY?.value || event.event.SUMMARY || "",
        description: event.event.DESCRIPTION || event.event["DESCRIPTION:"] || "",
        fromTime: start || "",
        toTime: end || "",
        done: false,
        list: []
    };

    const additionalParams = [];

    if (eventDetails.attach?.val) {
        additionalParams.push({
            key: "link",
            value: `${eventDetails.attach.val} ${eventDetails.attach.params.FILENAME || ''}`,
        });
    }

    if (eventDetails["X-GOOGLE-CONFERENCE"] || eventDetails["X-MICROSOFT-ONLINEMEETINGEXTERNALLINK"]) {
        additionalParams.push({
            key: "meetinglink",
            value: eventDetails["X-GOOGLE-CONFERENCE"] ||
                eventDetails["X-MICROSOFT-ONLINEMEETINGEXTERNALLINK"] ||
                null,
        });
    }
    if (eventDetails.ORGANIZER?.value?.split(":")[eventDetails.ORGANIZER?.value?.split(":").length - 1]) {
        additionalParams.push({
            key: "organizer",
            value: eventDetails.ORGANIZER.value.split(":")[eventDetails.ORGANIZER.value.split(":").length - 1],
        });
    }

    if (eventDetails.LOCATION?.value || eventDetails.LOCATION) {
        additionalParams.push({
            key: "location",
            value: eventDetails.LOCATION?.value ?? eventDetails.LOCATION,
        });
    }

    if (eventDetails.TRIGGER?.value || eventDetails.TRIGGER) {
        additionalParams.push({
            key: "trigger",
            value: eventDetails.TRIGGER?.value ?? eventDetails.TRIGGER,
        });
    }

    if (eventDetails.ATTENDEES) {
        additionalParams.push(...handleAttendees(eventDetails.ATTENDEES));
    }
    params.list.push(...additionalParams.filter(param => param.value !== null));
    params.list.push({ key: statusKey, value: statusKey });
    return params;
}

export const updateICS = async (decryptedFile, userName, acceptValue) => {
    return new Promise((resolve, reject) => {
        try {
            const userHost = userName.split("@")[1];
            const email = userName.replace(userHost, config.VERFIED_DOMAIN);
            let partStat = 'ACCEPTED';
            if (acceptValue === "accept") {
                partStat = "ACCEPTED";
            } else if (acceptValue === "maybe") {
                partStat = "TENTATIVE";
            } else {
                partStat = "DECLINED";
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    function formatDateToIcs(isoString) {
                        const date = new Date(isoString);
                        return date.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
                    }

                    const icsContent = e.target.result;
                    const parsedIcs = ICAL.parse(icsContent);
                    const comp = new ICAL.Component(parsedIcs);
                    const vevent = comp.getFirstSubcomponent('vevent');
                    const cn = userName.split("@")[0];
                    const xNumGuests = '0';
                    const updatedAttendee = `ATTENDEE;CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=${partStat};RSVP=TRUE;CN=${cn};X-NUM-GUESTS=${xNumGuests}:mailto:${email}`;
                    const dtStart = formatDateToIcs(vevent.getFirstPropertyValue('dtstart'));
                    const dtEnd = formatDateToIcs(vevent.getFirstPropertyValue('dtend'));
                    const dtStamp = formatDateToIcs(vevent.getFirstPropertyValue('dtstamp'));
                    const organizer = vevent.getFirstPropertyValue('organizer');
                    const summary = vevent.getFirstPropertyValue('summary');
                    const description = vevent.getFirstPropertyValue('description');
                    const updatedIcsContent = [
                        "BEGIN:VCALENDAR",
                        "PRODID://DMail//Calendar//EN",
                        "VERSION:2.0",
                        "METHOD:REPLY",
                        "BEGIN:VEVENT",
                        `DTSTART:${dtStart}`,
                        `DTEND:${dtEnd}`,
                        `DTSTAMP:${dtStamp}`,
                        `ORGANIZER;CN=${organizer.split(':')[1]}:${organizer}`,
                        `UID:${vevent.getFirstPropertyValue('uid')}`,
                        updatedAttendee,
                        `DESCRIPTION:${description}`,
                        "LOCATION:",
                        "SEQUENCE:0",
                        "STATUS:CONFIRMED",
                        `SUMMARY:${summary}`,
                        "TRANSP:OPAQUE",
                        "END:VEVENT",
                        "END:VCALENDAR"
                    ].join("\n");
                    const blob = new Blob([updatedIcsContent], { type: 'text/calendar' });
                    const file = new File([blob], 'invite.ics', { type: 'text/calendar' });
                    resolve(file);
                } catch (error) {
                    console.error('Error processing ICS content:', error);
                    reject(error);
                }
            };

            reader.onerror = (error) => {
                console.error('Error reading file:', error);
                reject(error);
            };
            reader.readAsText(decryptedFile);
        } catch (e) {
            console.error('Error in updateICS function:', e);
            reject(e);
        }
    });
};

export const getEmailContents = async (subject, status) => {
    let updatedSubject;

    switch (status) {
        case 'accept':
            updatedSubject = 'Your Invitation Has Been Accepted';
            break;
        case 'maybe':
            updatedSubject = 'Your Invitation Response is Tentative';
            break;
        case 'no':
            updatedSubject = 'Your Invitation Has Been Declined';
            break;
        default:
            updatedSubject = subject;
            break;
    }

    return updatedSubject;
};
