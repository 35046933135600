import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { logo } from '../../assets/svg/svg'
import { CompleteLoader } from '../../modals/common-alert';
import { useSelector, useDispatch } from 'react-redux';
import { getHostHelperContract } from '../../helper/contract-helper';
import { toast, Bounce } from 'react-toastify';
import { transactionAction } from '../../helper/chain-helper';
import { getBlockedList } from '../../helper/settingsHelper.js';
import Loader from "../../components/Loader.js";
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import Web3 from 'web3';
import { set } from "rsuite/esm/internals/utils/date/index.js";
const cookies = new Cookies();


const Profile = () => {
    const [loader, setLoader] = useState(false);
    const [user] = useState(cookies.get("userObject"));
    const [account, setAccount] = useState('');
    const [web3Value, setWeb3] = useState(null);
    const [sectionLoader, setSectionLoader] = useState(false);
    const [state, setState] = useState([])
    const dispatch = useDispatch();
    const refreshSettings = useSelector((state) => state.refreshSettings);


    useEffect(() => {
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum);
            setWeb3(web3Instance);
            window.ethereum
                .request({ method: 'eth_accounts' })
                .then(accounts => {
                    if (accounts.length > 0) {
                        setAccount(accounts[0]);
                    }
                })
                .catch(err => console.error(err));
            window.ethereum.on('accountsChanged', accounts => {
                setAccount(accounts[0] || '');
            });
        } else {
            console.log('MetaMask is not installed');
        }
        fetchdata();
    }, []);

    const saveAddress = async () => {
        setLoader(true);
        const inputValue = document.getElementById('custom-tags').value;
        let addressValues;
        if(inputValue.trim()){
            addressValues = state.concat(inputValue);
        }else {
            addressValues = state;
        }
        const options = [
            {
                key: "address",
                value: addressValues.join(',')
            },
        ];
        const helperContract = getHostHelperContract();  
        const functionParams = [ user.name, 'blockedAddress', options];
        const hash = await transactionAction(helperContract , "updateSettings", functionParams , account);
        if(hash) {
            dispatch(  { type: "REFRESH_SETTINGS",  payload: !refreshSettings}  );
            toast.success("Successfully updated.", {
                position: "top-right",
                transition: Bounce,
            });
        }else{
            toast.error("Something went wrong.", {
                position: "top-right",
                transition: Bounce,
            });
        }
        await fetchdata();
        setLoader(false);
    }

    async function fetchdata(){
        setSectionLoader(true);
        const getBlocked = await getBlockedList();
        const addresses = getBlocked.address ? getBlocked.address.split(',') : [];
        setState(addresses);
        setSectionLoader(false);
    }
    const handleTagsChange = (value) => {
        const newTags = value.values;
        const uniqueTags = new Set();
        const validTags = [];

        newTags.forEach(tag => {
            if (tag.trim() !== '' && !uniqueTags.has(tag)) {
                uniqueTags.add(tag);
                validTags.push(tag);
            }
        });
        setState(validTags);
    };
    return <>
        {!sectionLoader ? (

            <div className='settings-filter-section'>
                <div>
                    <label for="fname">Blocked Addresses: <span className="profile-welcome">(separate each address with a comma)</span></label>
                    <InputTags 
                        values={state} 
                        onTags={handleTagsChange} 
                        className="custom-tags flex" 
                        id="custom-tags" 
                    />
                </div>
                <div className='save-settings-btn mt-5'  onClick={()=>{ saveAddress() }}>
                    <button>
                        <span>Save</span>
                    </button>
                </div>
            </div>
        ) : (
            <Loader normal={true} />
        )}
        <CompleteLoader isOpen={loader} />
    </>

}

export default Profile;