import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { logo } from '../../assets/svg/svg'
import { CompleteLoader } from '../../modals/common-alert';
import { useSelector, useDispatch } from 'react-redux';
import { getHostHelperContract } from '../../helper/contract-helper';
import { toast, Bounce } from 'react-toastify';
import { transactionAction } from '../../helper/chain-helper';
import { getProfileSettings } from '../../helper/settingsHelper.js';
import Loader from "../../components/Loader.js";
import { getFirstLetterUppercase } from '../../helper/object-validation-helper';
import Web3 from 'web3';
const cookies = new Cookies();


const Profile = () => {
    const [loader , setLoader ] = useState(false);
    const [user] = useState(cookies.get("userObject"));
    const [account, setAccount] = useState('');
    const [web3Value, setWeb3] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [sectionLoader , setSectionLoader ] = useState(false);
    const dispatch = useDispatch();
    const refreshSettings = useSelector((state) => state.refreshSettings);

    useEffect(() => {
        if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        window.ethereum
            .request({ method: 'eth_accounts' })
            .then(accounts => {
            if (accounts.length > 0) {
                setAccount(accounts[0]);
            }
            })
            .catch(err => console.error(err));
        window.ethereum.on('accountsChanged', accounts => {
            setAccount(accounts[0] || '');
        });
        } else {
            console.log('MetaMask is not installed');
        }
        fetchdata();
    }, []);

    async function fetchdata(){
        setSectionLoader(true);
        const profileSettings = await getProfileSettings();
        setFirstName(profileSettings.firstName);
        setLastName(profileSettings.lastName);
        setSectionLoader(false);
    }

    const saveProfile = async () => {
        setLoader(true);        
        const options = [
            {
                key: "firstName",
                value: firstName
            },
            {
                key: "lastName",
                value: lastName
            }
        ];

        const helperContract = getHostHelperContract();  
        const functionParams = [ user.name, 'profile', options];
        const hash = await transactionAction(helperContract , "updateSettings", functionParams , account);
        if(hash) {
            dispatch(  { type: "REFRESH_SETTINGS",  payload: !refreshSettings}  );
            toast.success("Successfully updated.", {
                position: "top-right",
                transition: Bounce,
            });
        }else{
            toast.error("Something went wrong.", {
                position: "top-right",
                transition: Bounce,
            });
        }
        await fetchdata();
        setLoader(false);
    }

    return <>
    {!sectionLoader ? (
        <div className='settings-profile-section'>
            <div className="d-flex justify-content-center align-items-center text-center">
                <div className="user-profile-pic rounded-circle width-150 height-150 f-size-90 f-weight-300">{getFirstLetterUppercase(user.name.split('@')[0] ?? '#')}</div>
            </div>
            <div className="flex row justify-content-start align-items-center text-start">
                <div className="form-group col-md-6 mt-4">
                    <label for="fname">First Name: </label>
                    <input type="text" className='common-input mt-1' id="fname" placeholder=" First Name..." value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                </div>
                <div className="form-group col-md-6 mt-4">
                    <label for="lname">Last Name:  </label>
                    <input type="text" className='common-input mt-1' id="lname" placeholder=" Last Name..." value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                </div>
                </div>
            <div className='save-settings-btn mt-5' onClick={()=>{ saveProfile() }}>
                <button>
                    <span>Save</span>
                </button>
            </div>  
        </div>
    ) : ( 
        <Loader normal={true}/>
    )}
        <CompleteLoader isOpen={loader} />
    </>

}

export default Profile;